export enum PopularStyles {
  MLA_8 = 'Modern Language Association 8th edition',
  MLA_9 = 'Modern Language Association 9th edition',
  APA_6 = 'American Psychological Association 6th edition',
  APA_7 = 'American Psychological Association 7th edition',
  CHICAGO_17 = 'Chicago Manual of Style 17th edition (full note)',
  AMA = 'American Medical Association 11th edition',
  HARVARD = 'Harvard',
  IEEE = 'IEEE',
  TURABIAN_8 = 'Turabian 9th edition (full note)',
  MHRA_3 = 'Modern Humanities Research Association 3rd edition (author-date)',
  VANCOUVER = 'Vancouver',
  ELSEVIER = 'Elsevier - Harvard (with titles)',
  OSCOLA = 'OSCOLA (Oxford University Standard for Citation of Legal Authorities)',
}

// TODO delete this and just use enum
const popularStyles = [
  PopularStyles.MLA_8,
  PopularStyles.MLA_9,
  PopularStyles.APA_6,
  PopularStyles.APA_7,
  PopularStyles.CHICAGO_17,
  PopularStyles.AMA,
  PopularStyles.HARVARD,
  PopularStyles.IEEE,
  PopularStyles.TURABIAN_8,
  PopularStyles.MHRA_3,
  PopularStyles.VANCOUVER,
  PopularStyles.ELSEVIER,
  PopularStyles.OSCOLA,
];

export default popularStyles;
