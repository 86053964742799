/* eslint-disable @next/next/inline-script-id */
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import React from 'react';
import { AppThunkDispatch, wrapper } from '../redux/store';
import {
  setOriginalRequestId,
  setUserData,
  setUserNotPresent,
} from 'redux/modules/auth-module';
import * as AuthModel from 'web/models/auth-model';
import Head from 'next/head';
import createWebUrl from 'lib/create-web-url';
import Script from 'next/script';
import yn from 'yn';
import arrayFirstOrSelf from 'lib/array-first-or-self';
import axios from 'axios';
import { skipSession as skipSessionHeader } from 'api/lib/session-headers';
import createApiUrl from 'lib/create-api-url';
import { reportGeneralException } from 'lib/sentry';
import 'react-responsive-modal/styles.css';
import './styles/global.css';
import '../css/sharing-modal.css';
import '../css/information-modal.css';
import '../css/app.css';
import '../css/gdocs-app.css';
import '../css/word-app.css';
import '../css/page.css';
import '../css/button.css';
import '../css/variables.css';

if (yn(process.env.GDOC) || yn(process.env.CHROME)) {
  axios.interceptors.request.use((config) => {
    const isApiUrl = config.url?.startsWith(createApiUrl());
    if (!isApiUrl) {
      return config;
    }
    if (config.headers) {
      config.headers[skipSessionHeader] = 'true';
    }
    return config;
  });
}

interface CustomProps {
  err?: unknown;
}
class BibcitationApp extends App<CustomProps> {
  public static getInitialProps = wrapper.getInitialAppProps(
    (store) =>
      async ({ Component, ctx }: AppContext): Promise<AppInitialProps> => {
        let errorToSend;
        const isServer = !!ctx.req;
        const isChrome = ctx.pathname === '/chrome'; // when running dev server do not include session data
        const isWordAddInRequest = ctx.pathname === '/word';

        if (isServer) {
          const dispatch = store.dispatch as AppThunkDispatch; // https://github.com/kirill-konshin/next-redux-wrapper/issues/207#issuecomment-710063652
          const headers = ctx.req?.headers;
          const requestId = arrayFirstOrSelf(headers?.['x-request-id']);
          const cookies = headers?.cookie;
          dispatch(setOriginalRequestId(requestId));
          try {
            if (isChrome || isWordAddInRequest) {
              dispatch(setUserNotPresent());
            } else {
              const res = await AuthModel.getMe(cookies, requestId);
              dispatch(setUserData(res.data));
              const cookieHeader = res.headers['set-cookie'];
              if (cookieHeader) {
                ctx.res?.setHeader('Set-Cookie', cookieHeader);
              }
            }
          } catch (e: any) {
            if (axios.isAxiosError(e)) {
              const cookie = e.response?.headers?.['set-cookie'];
              if (cookie) {
                ctx.res?.setHeader('Set-Cookie', cookie);
              }
              dispatch(setUserNotPresent());
              if (e.response?.status !== 401) {
                reportGeneralException('getMe error caught', {
                  code: e.code,
                  responseStatus: e.response?.status,
                  responseText: e.response?.statusText,
                });
              }

              // Need handling for ECONNREFUSED (tell user we could not load data)
              if (e.code === 'ECONNREFUSED') {
                reportGeneralException('Connection refused error', {
                  headers: ctx.req?.headers,
                  code: e.code,
                });
                errorToSend = e;
              }
            } else {
              reportGeneralException('getMe error caught -- not axios', {
                code: e.code,
                responseStatus: e.response?.status,
                responseText: e.response?.statusText,
              });
            }
          }
        }

        return {
          pageProps: {
            // Call page-level getInitialProps
            ...(Component.getInitialProps
              ? await Component.getInitialProps(ctx)
              : {}),
            error: errorToSend,
          },
        };
      },
  );

  render(): JSX.Element {
    const { Component, pageProps, err } = this.props;
    return (
      <React.StrictMode>
        <Head>
          <meta
            name="keywords"
            content="bibliography, citation, create citations, works cited, generate citations, bibliographies, online citations, citation tool, free citation generator, mla, apa"
          />
          <meta
            name="description"
            content="A free citation generator that creates bibliographies, references and works cited. Automatically generate MLA, APA, Chicago, etc. citations and create an accurate bibliography in one click."
          />
          <meta
            property="og:title"
            content="Bibcitation: Free Automatic Citation Maker. MLA, APA + more"
          />
          <meta property="og:site_name" content="Bibcitation" />
          <meta property="og:type" content="website" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="600" />
          <meta
            property="og:image"
            content={createWebUrl('/images/bibcitation-og-image.png')}
          />
        </Head>
        {!yn(process.env.CHROME) && !yn(process.env.GDOCS) && (
          <>
            <Script
              strategy="lazyOnload"
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_CODE}`}
            />
            <Script
              strategy="afterInteractive"
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `
                function isMobile() {
                  const toMatch = [
                      /Android/i,
                      /webOS/i,
                      /iPhone/i,
                      /iPad/i,
                      /iPod/i,
                      /BlackBerry/i,
                      /Windows Phone/i
                  ];
                  return toMatch.some((toMatchItem) => {
                      return navigator.userAgent.match(toMatchItem);
                  });
                }
                if (!isMobile()) {
                  var s = document.createElement('script');
                  s.type = 'text/javascript';
                  s.src = 'https://accounts.google.com/gsi/client';
                  s.async = true;
                  s.defer = true;
                  var x = document.getElementsByTagName('script')[0];
                  x.parentNode.insertBefore(s, x);
                }
            `,
              }}
            />
            <Script
              strategy="afterInteractive"
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `
                if (typeof window.mixpanel === 'undefined') {
                  (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
                  for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
                  
                  mixpanel.init('${process.env.MIXPANEL_API_KEY}', {
                    persistence: 'localStorage',
                    loaded: (mixpanel) => {
                      if (window.anonymousProperties) {
                        window.setAnonymousProperties(mixpanel.get_distinct_id(), window.anonymousProperties);
                      }
                    }
                  });
                }
                `,
              }}
            />
          </>
        )}
        <Component {...pageProps} err={err} />
      </React.StrictMode>
    );
  }
}

export default wrapper.withRedux(BibcitationApp);
